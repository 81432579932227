import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const ContainerWrapper = styled.div`
  .job-block {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    padding: 2rem;
    .job-overview {
      align-items: center;
      color: white;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      font-family: 'Open Sans', Verdana;
      font-size: 1.4rem;
      justify-content: space-between;
      ${MEDIA.TABLET`
        justify-content: center;
        padding-right: 0;
      `};
    }
    .job-title {
      margin: 0 auto 0 2rem;
      ${MEDIA.TABLET`
        max-width: 20rem;
      `};
    }
    .job-text {
      cursor: pointer;
      min-width: 30rem;
    }
    .job-location, .job-commitment {
      margin: 0 1.5rem;
      text-align: center;
      width: 15%;
      ${MEDIA.TABLET`
        display: none;
      `};
    }
    .job-details {
      align-items: center;
      color: #969EBE;
      display: flex;
      font-size: 1.2rem;
      padding: 2rem 0 0;
      text-transform: uppercase;
      div:after {
        color: #8C9EFF;
        content: '●';
        display: inline-block;
        font-size: 1rem;
        margin-right: 1rem;
        padding-left: 1rem;
        vertical-align: top;
      }
      div:last-of-type {
        &:after {
          content: none;
        }
      }
      ${MEDIA.TABLET`
        justify-content: center;
      `};
    }
    .tools {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 0 0 2rem;
      ${MEDIA.TABLET`
        margin-bottom: 1rem;
      `};
      .copy-link-container {
        position: relative;
      }
      .copy-link {
        cursor: pointer;
        fill: white;
      }
      .tool-tip {
        background: rgba(150,158,190,0.4);
        border-radius: 2px;
        font-family: aktiv-grotesk, sans-serif;
        font-size: 1.2rem;
        line-height: 1.1;
        pointer-events: none;
        opacity: 0;
        padding: 0.6rem;
        position: absolute;
        right: 3rem;
        top: 0;
        &.active {
          animation: tooltipFade;
          animation-duration: 0.8s;
        }
      }
    }
    .job-listing {
      /* background: #244F8E; */
      font-family: 'Open Sans', verdana;
      max-height: 0;
      padding: 0 1rem;
      overflow: hidden;
      .hidden {
        height: 1px;
        position: fixed;
        right: -50rem;
        width: 1px;
      }
      &.expanded {
        margin-top: 2rem;
        max-height: 210rem;
        padding: 3rem;
        text-align: left;
        transition: max-height 0.15s linear;
      }
      div, span {
        font-size: 1.4rem !important;
        line-height: 1.4;
      }
      h3 {
        font-size: 2.2rem;
        padding: 1rem 0;
      }
      li {
        list-style: disc;
        margin-left: 2rem;
      }
    }
  }
  .simple-arrow {
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #FFF;
    transform: none;
    transition: transform 0.1s ease-in-out;
    &.expanded {
      transform: rotate(90deg);
    }
  }
  @keyframes tooltipFade {
    0% {
      opacity: 0;
      top: 0;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      top: -3rem;
    }
  }
  .button {
    background: #FFF;
    color: #d10c49;
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 3rem 0 0;
    padding: 2rem 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    max-width: 180px;
  }
  .eeo {
    font-family: "Open Sans", verdana;
    font-size: 1.3rem;
    line-height: 1.2;
    margin: 4rem 0 0;
    a {
      color: #FFF;
    }
  }
`;