import styled from 'styled-components';

const PageWrapper = styled.div`
	position: relative;
	text-align-center;
	.cta-info {
		color: #FFF;
		margin: 10rem auto;
		text-align: center;
	}
  .career-listing-container {
    margin: 0 auto;
  }
  .team-block {
    margin: 2rem 0;
    h3 {
      color: white;
      font-family: 'Open Sans', sans-serif;
      font-size: 3rem;
      margin: 0 0 1rem 2rem;
			text-align: left;
    }
  }
  .g-JobBlock {
    &:nth-of-type(even), &:only-child {
      /* background-color: #142743;  */
      .job-listing.expanded {
				background-color: #f55185;
      }
    }
    &:nth-of-type(odd) {
			background-color: #d10c49;
    }
  }
  .career-listings {
    font-family: 'Open Sans', sans-serif;
		margin: 0 auto;
		max-width: 120rem;
    padding-top: 2rem;
    h2 {
      color: #FFF;
      font-family: 'Open Sans', sans-serif;
      font-size: 5rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
	p {
		font-size: 2.4rem;
		line-height: 1.7;
		margin: 3rem auto 3rem;
		max-width: 85rem;
		a {
			color: #FFF;
			font-style: italic;
			font-weight: bold;
		}
	}
	.jobs-wrapper {
		margin: 0 auto;
		max-width: 120rem;
	}
`;

export default PageWrapper;