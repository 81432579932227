import React, { Component } from 'react';
import { ContainerWrapper } from './HomeHeader.css';
import { Link } from 'gatsby';
// import { Swiper, Slide } from 'react-dynamic-swiper';
// import 'react-dynamic-swiper/lib/styles.css';

class HomeHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
    	activeSlide: 1,
    	intervalId: null,
    	timeoutId: null
    };
  }

  rotateContent = (restart) => {
  	this.setState({activeSlide: this.state.activeSlide === 3 ? 1 : (this.state.activeSlide + 1)})
  }

  selectContent = (id)=> {
  	window.clearInterval(this.state.intervalId);
  	this.setState({activeSlide: id});
  	let timeoutId = window.setTimeout(() => {
  		this.restartInterval(4000);
  		window.clearTimeout(timeoutId);
  	}, 10000);
  }

  restartInterval = (intervalLength) => {
  	window.clearTimeout(this.state.timeoutId);
  	window.clearInterval(this.state.intervalId);
  	let intervalId = window.setInterval(this.rotateContent, intervalLength);
  	this.setState({intervalId: intervalId});
  }

  componentDidMount() {
  	if (this.props.rotate) {
  		this.restartInterval(4000);
  	}
  	else {
  		this.setState({activeSlide: this.props.headerImage});
  	}
  }

	render() {
		return (
	    <ContainerWrapper className="g-HomeHeader" style={{backgroundImage: `url('https://webcdn.hirezstudios.com/redbeardgames/images/HomeCarouselImages/${this.state.activeSlide}.jpg`}}>
	    	<div className="overlay" />
	    	{this.props.button &&
	    	<div className="headline-button-container">
	    		<h1>{this.props.headlineCopy}</h1>
	    		<Link to={this.props.buttonPath} className="button">{this.props.buttonCopy}</Link>
	    	</div>
	    	}
	    	{!this.props.button &&
	    	<div className="carousel">
			    <div className={`slide-content logo ${(this.state.activeSlide === 1 || typeof this.state.activeSlide === 'string')  ? 'active' : ''}`}>
			    	<img src="https://webcdn.hirezstudios.com/redbeardgames/Logos/RBG_Final-14.png" alt="Red Beard Games Logo" />
			    </div>
			    <div className={`slide-content physics text ${this.state.activeSlide === 2? 'active' : ''}`}>
			    	<p>Physics Based Arena</p>
			    </div>
			    <div className={`slide-content evolving text ${this.state.activeSlide === 3? 'active' : ''}`}>
			    	<p>Evolving Battlegrounds</p>
			    </div>
	    	</div>
		    }
	    	{this.props.rotate &&
	    	<div className="carousel-navigation">
	    		<div className={`carousel-nav-link ${this.state.activeSlide === 1? 'active' : ''}`} onClick={() => this.selectContent(1)} />
	    		<div className={`carousel-nav-link ${this.state.activeSlide === 2? 'active' : ''}`} onClick={() => this.selectContent(2)} />
	    		<div className={`carousel-nav-link ${this.state.activeSlide === 3? 'active' : ''}`} onClick={() => this.selectContent(3)} />
	    	</div>
		    }
	    </ContainerWrapper>
		);
	}
}

export default HomeHeader;