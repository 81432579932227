import React, { Component } from 'react';
import PageWrapper from '../styles/careers.css.js';
import HomeHeader from '../components/Home/HomeHeader/HomeHeader';
import JobBlock from '../components/JobBlock/JobBlock.js';

const apiDomain = process.env.NODE_ENV === 'development' ? 'https://localhost:3006' : 'https://api.hirezstudios.com';
class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: undefined
    };
  }

  getJobs = () => {
    //This empty object will house all of the studios as keys with teams and jobs nested as values
    let studiosObj = {};
    //The code within the fetch is to resort all of the data we get from lever and present it differently on the careers page.
    fetch(`${apiDomain}/acct/leverJobs`)
    .then((response) => response.json()).then((response) => {
      response.forEach(job => {
        if (job.categories.department === "Red Beard Games") {
          if (!studiosObj.hasOwnProperty(job.categories.department)) {
            studiosObj[job.categories.department] = [];
          }
          studiosObj[job.categories.department].push(job);
        }
      });
      Object.keys(studiosObj).map((studio, index) => {
        let team = {};
        studiosObj[studio].forEach(job => {
          if (!team.hasOwnProperty(job.categories.team)) {
            team[job.categories.team] = [];
          }
          team[job.categories.team].push(job);
        });
        studiosObj[studio] = team;
        return null;
      });
      this.setState({jobs: studiosObj});
    });
  }

  componentDidMount() {
    this.getJobs();
  }

  render() {
    return (
      <PageWrapper>
        <HomeHeader headerImage="3" rotate={false}/>
        <div className="cta-info">
          <div className="underline-header">Career Opportunities</div>
          <div className="career-listings">
            {this.state.jobs && Object.keys(this.state.jobs["Red Beard Games"]).map((team, teamIndex) => 
            <div className="team-block" key={team + teamIndex}>
              <h3>{team}</h3>
              <div className="jobs-wrapper">
              {this.state.jobs["Red Beard Games"][team].map((job, jobIndex) => 
                <JobBlock key={job.id} job_data={job} />
              )}
              </div>
            </div>
            )}
          </div>
        </div>
      </PageWrapper>
    );
  };
}

export default Careers;
